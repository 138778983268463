<template>
  <div class="detail-container">
    <div>详情页</div>
  </div>
</template>

<script>
export default {
  name: "Detail",
  data() {
    return {
      bookList: [
        { name: "山海地図", link: "/PageData" },
        { name: "山海人物誌", link: "/PageData" },
        { name: "山海霊宝録", link: "/PageData" },
        { name: "山海妖魔誌", link: "/PageData" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>